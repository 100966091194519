/* eslint-disable @typescript-eslint/no-unsafe-assignment, camelcase */
import { FC, useEffect, useRef, useState } from 'react';
import clx from 'classnames';
import { Button, Icon, Loader, Typography } from '@modulor/react';

import { FlatStringObject } from 'commonTypes';
import { getIsOpenedInPopUp, getLayoutType, inIframe, LayoutType } from 'utils';
import IntegrationCommunication from 'services/integration-communication';
import { FlowTypes, REDIRECT_FLOW_TYPES, SUBMIT_FORM_ACTIONS, SUBMIT_REDIRECT_ERROR_TIME } from 'store/constants';
import {
  PayHubCashierContractsCashier3DSResultResponse,
  PayHubWidgetContractsSubmitPaymentResponse,
  PayHubWidgetContractsSubmitPayoutResponse,
  PayHubDomainSessionsEnumsPlatformType,
} from 'store/models';
import { APP_NAME, pmAction } from 'utils/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { paymentFlowSelector, platformSelector } from 'store/slices/global';
import { setCarouselViewOff } from 'store/slices/carousel';
import {
  Clickstream,
  getClickStreamCashierContext,
  getClickStreamEvent,
  getClickStreamPayGroupCashierContext,
} from 'services/clickstream';
import { useCustomTranslation, useFrameChangeHeight, useGetSessionId, useNavigateWithSearch } from 'Modulor/hooks';
import { NotificationService } from 'services/notificationService/NotificatioinService';
import { paySessionStateTargetName, submitRedirectTargetName } from 'services/notificationService/constants';
import { paySessionStateSelector } from 'store/slices/paySessionState';
// eslint-disable-next-line max-len
import { paySessionStateSubmitFormHandlerCreator } from 'services/notificationService/handlers/paySessionStateSubmitFormHandler';
import { errorSubmitFormHandlerCreator } from 'services/notificationService/handlers/errorSubmitFormHandler';
import { submitRedirectErrorSelector } from 'store/slices/submitForm';
import {
  closeCashierAfterOpenInNewTab,
  formatParamsForForm,
  getFormTarget,
  getUrlToClose,
} from './utils';
import HiddenFields from './HiddenFields';
import CustomIframe from './CustomIframe';

import './SubmitForm.scss';

export interface RequestFields {
  [propName: string]: unknown;
}

type BodyTitle = {
  standartText?: string;
  titleLinkText?: string;
};

interface SubmitFormProps {
  redirectForm: PayHubWidgetContractsSubmitPaymentResponse |
  PayHubWidgetContractsSubmitPayoutResponse |
  PayHubCashierContractsCashier3DSResultResponse;
  isAwait?: boolean;
  paymentType: FlowTypes;
  isLLA?: boolean;
  methodName?: string;
}

const TRANSACTION_STATUS_MAP: FlatStringObject = {
  processing: 'processing',
  success: 'success',
  fail: 'error',
  processing_error: 'error',
  order_cancelled: 'error',
  awaiting: 'await',
  await: 'await',
};

export const SubmitForm: FC<SubmitFormProps> = (props) => {
  const dispatch = useAppDispatch();
  const clickstream = Clickstream.use();
  const flowType = useAppSelector(paymentFlowSelector);
  const isRedirectOk = useAppSelector(submitRedirectErrorSelector);
  const navigate = useNavigateWithSearch();
  const cashierContext = getClickStreamCashierContext(flowType);
  const payGroupContext = getClickStreamPayGroupCashierContext();
  const formRef = useRef<HTMLFormElement>(null);
  const [isFormHandled, setIsFormHandled] = useState(false);
  const {
    redirectForm,
    paymentType,
    isAwait,
    isLLA = false,
    methodName,
  } = props;
  const {
    action,
    request_method: requestMethod,
    redirect_type,
  } = redirectForm;

  const layoutType = getLayoutType();
  const isMobileLayout = layoutType === LayoutType.mobile;
  const platform = useAppSelector(platformSelector);
  const isNativeDevice = [
    PayHubDomainSessionsEnumsPlatformType.NativeIos,
    PayHubDomainSessionsEnumsPlatformType.NativeAndroid,
  ].includes(platform);
  const { t } = useCustomTranslation();

  const paySessionState = useAppSelector(paySessionStateSelector);
  const {
    transaction_status = null,
    result_status,
  } = paySessionState || {};

  const { requestUrl, requestFields } = formatParamsForForm(redirectForm);
  const isActionExpected = [
    SUBMIT_FORM_ACTIONS.redirect as string,
    SUBMIT_FORM_ACTIONS.blank as string,
  ].includes(action as string);
  const requestSessionTime = 30000;
  const isDeposit = window.location.pathname.includes(FlowTypes.deposit);
  const formTarget = getFormTarget({
    action,
    isActionExpected,
    redirectType: redirect_type,
    isLLA,
  });

  useFrameChangeHeight(isFormHandled);

  const isRedirectTypeParent = redirect_type && redirect_type === 'parent';
  const isInWeb = inIframe();
  const isInsideCustomIframe = isInWeb && formTarget === '_self' && !isRedirectTypeParent;
  const sessionId: string = useGetSessionId();
  const notificationService = !isInsideCustomIframe ? NotificationService.getInstance(sessionId) : null;
  const notificationServiceCallback = paySessionStateSubmitFormHandlerCreator({
    dispatch,
  });
  const errorHandlerRedirectCallback = errorSubmitFormHandlerCreator({
    dispatch,
  });

  useEffect(() => {
    if (!isRedirectOk && formRef.current) {
      formRef.current.target = REDIRECT_FLOW_TYPES.top;

      try {
        formRef.current.submit();
      } catch {
        formRef.current.dispatchEvent(new Event('submit'));
      }
    }
  }, [isRedirectOk]);

  useEffect(() => {
    let submitRedirectErrorTimer: NodeJS.Timeout;
    async function start() {
      await notificationService?.start();
      notificationService?.subscribe({
        name: paySessionStateTargetName,
        callback: notificationServiceCallback,
      });
      notificationService?.subscribe({
        name: submitRedirectTargetName,
        callback: errorHandlerRedirectCallback,
      });
      submitRedirectErrorTimer = setTimeout(async () => {
        await notificationService?.stop(submitRedirectTargetName);
        clearTimeout(submitRedirectErrorTimer);
      }, SUBMIT_REDIRECT_ERROR_TIME);
    }
    async function onStop() {
      clearTimeout(submitRedirectErrorTimer);
      await notificationService?.stop(paySessionStateTargetName);
      await notificationService?.stop(submitRedirectTargetName);
    }

    dispatch(setCarouselViewOff());
    start();

    const timer = setTimeout(() => {
      onStop();
    }, requestSessionTime);

    return () => {
      clearTimeout(timer);
      onStop();
    };
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    async function onStop() {
      await notificationService?.stop(paySessionStateTargetName);
    }

    const timer = setTimeout(() => {
      onStop();
    }, requestSessionTime);

    return () => {
      clearTimeout(timer);
      onStop();
    };
  }, [paymentType]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isFormHandled) {
        return;
      }

      if (!formRef.current) {
        return;
      }

      const clickstreamEvent = getClickStreamEvent.cashier_method_redirect_page_open;
      clickstream.push(clickstreamEvent, [cashierContext,
        payGroupContext]);

      try {
        formRef.current.submit();
      } catch {
        formRef.current.dispatchEvent(new Event('submit'));
      }
      setIsFormHandled(true);

      if (platform === 'desktop') {
        closeCashierAfterOpenInNewTab(redirect_type);
      }
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [
    isFormHandled,
    paymentType,
    formTarget,
  ]);

  useEffect(() => {
    const workStatus = transaction_status || result_status;
    const providerIframe = document.querySelector('#iframe-host');

    if (workStatus && isDeposit && !providerIframe) {
      const mappedStatus = TRANSACTION_STATUS_MAP[`${workStatus}`];

      if (mappedStatus) {
        navigate(`/${paymentType}/${paymentType}-${mappedStatus}`);
      }
    }
  }, [
    transaction_status,
    result_status,
    paymentType,
  ]);

  if (!isActionExpected) {
    return null;
  }

  const handleSubmitForm = () => {
    const CHANGE_BUTTON_TIMEOUT = 3000;

    if (!isFormHandled) {
      if (isAwait) {
        setIsFormHandled(true);
      } else {
        const timer = setTimeout(() => {
          setIsFormHandled(true);
          clearTimeout(timer);
        }, CHANGE_BUTTON_TIMEOUT);
      }
    }
  };

  const handleGoBackClick = () => {
    if (getIsOpenedInPopUp()) {
      IntegrationCommunication.sendMessage({
        id: 'closeCashier',
      });
      return;
    }

    if (isInWeb) {
      IntegrationCommunication.sendMessage({
        id: 'redirectTo',
        value: '/',
      });
      IntegrationCommunication.sendMessage({
        id: 'successClick',
        target: APP_NAME,
        value: pmAction.deposit,
      });
    } else {
      if (isNativeDevice) {
        const closeUrl = getUrlToClose();
        window.location.assign(closeUrl);
        return;
      }

      window.location.href = window.location.origin;
    }
  };

  const handleRedirectClick = () => {
    const clickstreamEvent = getClickStreamEvent.cashier_method_redirect_link_click;
    clickstream.push(clickstreamEvent, [cashierContext,
      payGroupContext]);
  };

  const content = () => {
    if (isInsideCustomIframe) {
      const methodNameNoSpaces = (methodName || '').replace(/ /g, '_');
      const methodNameLowercase = methodNameNoSpaces.toLowerCase();

      return (
        <CustomIframe
          methodName={methodNameLowercase}
        >
          <section
            className={clx('submit-container', {
              fullWidth: true,
            })}
            style={{
              width: 'calc(100% - 32px)',
            }}
          >
            <div className="absolute-loader">
              <Loader />
            </div>
            <form
              onSubmit={handleSubmitForm}
              className="form"
              method={requestMethod}
              action={requestUrl}
              acceptCharset="utf-8"
              target={formTarget}
              ref={formRef}
            >
              <HiddenFields
                requestFields={requestFields}
              />
            </form>
          </section>
        </CustomIframe>
      );
    }
    let bodyTitle: BodyTitle = {};
    if (flowType === FlowTypes.deposit) {
      const depositBodyTranslation = t('PH.DEPOSIT.STATUS_PAGE.BODY.ALLOW_POPUPS');
      const standartText = depositBodyTranslation.split('<0>')[0];
      const titleLinkText = depositBodyTranslation.split('<0>')[1];

      bodyTitle = {
        standartText,
        titleLinkText,
      };
    } else {
      bodyTitle = {
        standartText: t('PH.WITHDRAWAL.STATUS_PAGE.BODY.ALLOW_POPUPS'),
      };
    }

    return (
      <section className="submit-container">
        <div className="submit-container__block">
          <Icon
            className="submit-container__info-link"
            name="info_circle_outlined"
            fill="icon-warning"
            size={40}
          />
          <Typography
            variant="title-2-semibold"
            style={{
              color: 'var(--content-title,var(--text-title))',
            }}
            className="submit-container__notice-sub-text"
          >
            {t(`PH.${flowType.toUpperCase()}.STATUS_PAGE.ALLOW_POPUPS.TITLE`)}
          </Typography>

          <form
            onSubmit={handleSubmitForm}
            className="form"
            method={requestMethod}
            action={requestUrl}
            acceptCharset="utf-8"
            target={formTarget}
            ref={formRef}
          >
            <HiddenFields
              requestFields={requestFields}
            />
            {isFormHandled && !isLLA ? (
              <div>
                <Typography
                  variant="body-regular"
                  className="submit-container__notice-text"
                >
                  <div>
                    {bodyTitle.standartText}
                  </div>
                  <Button
                    className="submit-container__submit-link"
                    role="link"
                    variant="text_button"
                    type="submit"
                    size="low"
                    onClick={handleRedirectClick}
                  >
                    {bodyTitle.titleLinkText}
                  </Button>
                </Typography>
              </div>
            ) : (
              <Loader />
            )}
          </form>
        </div>

        {!isLLA && isDeposit && (
          <div className="submit-container__button-container">
            <Button
              onClick={handleGoBackClick}
              type="button"
              variant="secondary"
            >
              {t('PH.BUTTON.CANCEL')}
            </Button>
          </div>
        )}
      </section>
    );
  };

  return (
    <div
      id="iframe-host"
      className={clx({
        mobile: isMobileLayout,
      })}
    >
      {content()}
    </div>

  );
};
