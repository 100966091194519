/* eslint-disable camelcase */
import qs from 'query-string';
import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import { RootState } from 'store';
import { getSubmitCheckoutUrl, setReqHeaders, softLocalStorageGet } from 'utils';
import { FINGERPRINT } from 'store/constants';
import {
  PayHubCashierContractsPaymentsSubmitPaymentGroupRequest,
  PayHubWidgetContractsSubmitPaymentResponse,
  PayHubWidgetContractsSubmitPayoutResponse,
  PayHubWidgetContractsRecurringCheckResponse,
} from 'store/models';
import { FlowTypes } from '../constants';

export interface CustomServerError {
  status: Pick<FetchBaseQueryError, 'status'> | string | number;
  errorMessage?: string;
  errorCode?: number;
}

type ExtendedFetchBaseQueryError = FetchBaseQueryError & {
  data?: {
    error_message: string;
    error_code: number;
  };
};

interface CheckoutSubmitRequestProps {
  flowType: FlowTypes;
  paygroupId: string;
  requestBody: PayHubCashierContractsPaymentsSubmitPaymentGroupRequest;
}

const CASHIER_SERVICE_ENV = process.env.NODE_ENV === 'production' ? '/api/cashier-service/' : '/cash-ph20-service/';
const CASHIER_API = `${CASHIER_SERVICE_ENV}v1.0`;

export const checkoutAPI = createApi({
  reducerPath: 'checkoutAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: CASHIER_API,
    prepareHeaders(headers, { getState }) {
      const { session_id = '' } = qs.parse(window?.location?.search || '');
      const { sessionId } = (getState() as RootState).common;
      setReqHeaders(headers, sessionId || `${session_id}`);
    },
  }),
  endpoints(builder) {
    return {
      submitCheckout: builder.query<
        PayHubWidgetContractsSubmitPaymentResponse
        | PayHubWidgetContractsSubmitPayoutResponse,
        CheckoutSubmitRequestProps
      >({
        query: ({
          flowType,
          paygroupId,
          requestBody,
        }) => ({
          url: getSubmitCheckoutUrl(flowType, paygroupId),
          method: 'POST',
          body: requestBody,
        }),
        transformErrorResponse: (response: ExtendedFetchBaseQueryError): CustomServerError => ({
          status: response?.status,
          errorMessage: response?.data?.error_message,
          errorCode: response?.data?.error_code,
        }),
      }),
      getUserCards: builder.query({
        query: () => {
          const fingerprint = softLocalStorageGet(FINGERPRINT);
          let headers = {};
          if (fingerprint) {
            headers = {
              'X-Fingerprint': fingerprint,
            };
          }
          return ({
            url: '/user-cards',
            method: 'GET',
            headers,
          });
        },
      }),
      deleteUserCard: builder.mutation<{ success: boolean; cardId: number }, number>({
        query(cardId) {
          return {
            url: '/user-cards',
            method: 'DELETE',
            body: {
              card_id: cardId,
              withholding_type: 'Payment',
            },
          };
        },
      }),
      recurringCheck: builder.query<PayHubWidgetContractsRecurringCheckResponse, { cardId: number; amount: number }>({
        query: ({ cardId, amount }) => ({
          url: '/user-cards/recurring-check',
          method: 'POST',
          body: {
            user_card_id: cardId,
            amount,
          },
        }),
      }),
    };
  },
});

export const {
  useSubmitCheckoutQuery,
  useLazyGetUserCardsQuery,
  useDeleteUserCardMutation,
  useLazyRecurringCheckQuery,
} = checkoutAPI;

export default checkoutAPI.reducer;
