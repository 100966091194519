import { FC, useEffect, useRef, useState } from 'react';
import { Button, Icon, Tooltip, Typography } from '@modulor/react';
import { displayTooltip } from 'utils';
import { useCustomTranslation, useOnClickOutside } from 'Modulor/hooks';
import { getPrettierFormatNumber } from 'utils/dataTransform';
import clx from 'classnames';
import './TaxInfo.scss';

export interface TaxInfoProps {
  taxValueAmount: number;
  percentTax?: number;
  isTaxHintShown: boolean;
  currency?: string;
  name?: string;
  description?: string;
  deductibleStakeAmount?: number;
}

export const TaxInfo: FC<TaxInfoProps> = (props) => {
  const {
    taxValueAmount,
    percentTax,
    isTaxHintShown,
    currency,
    name,
    description,
    deductibleStakeAmount,
  } = props;
  const { t } = useCustomTranslation();
  const hintRef = useRef<HTMLDivElement | null>(null);
  const [isOpenedHint, showHint] = useState(false);

  useEffect(() => {
    displayTooltip(false);
  }, []);

  useOnClickOutside(hintRef, () => showHint(false));

  if (!isTaxHintShown) {
    return null;
  }

  const toggleTaxInfoHint = () => {
    if (isOpenedHint) {
      showHint(false);
      displayTooltip(false);
      return;
    }
    displayTooltip(true);
    showHint(true);
  };

  const taxPercentValue = `${(percentTax && percentTax * 100) || 0}%`;
  return (
    <div
      className={clx('tax-info-block', {
        'hint-only': isTaxHintShown,
        'is-opened': isOpenedHint,
      })}
    >
      {deductibleStakeAmount && (
      <div className="deductible-amount">
        <Typography variant="subhead-regular">
          {t('PH.WITHDRAWAL.TAX.DEDUCTIBLE_STAKE')}
          :
        </Typography>
        <Typography
          variant="subhead-regular"
        >
          {`${getPrettierFormatNumber(deductibleStakeAmount)} ${currency}`}
        </Typography>
      </div>
      )}
      <div className="tax-general-block">
        <div>
          <Typography variant="subhead-regular">{t('PH.WITHDRAWAL.TOOLTIP.TAX.TITLE')}</Typography>
          {' '}
          <Typography variant="subhead-regular">
            {taxPercentValue}
          </Typography>
          <span
            ref={hintRef}
          >
            <Tooltip
              placement="bottom"
              show={isOpenedHint}
              className={clx('info-tooltip', {
                'hide-hint': !isTaxHintShown,
              })}
              heading={name ? t(name) : t('PH.WITHDRAWAL.TOOLTIP.TAX.TITLE')}
              text={description ? t(description) : t('PH.WITHDRAWAL.TOOLTIP.TAX.BODY')}
              button={(
                <Button
                  variant="primary"
                  className="info-tooltip-button"
                  onClick={toggleTaxInfoHint}
                  label={t('PH.BUTTON.ACCEPT')}
                />
                  )}
            >
              {[<Icon
                key="info-icon"
                onClick={toggleTaxInfoHint}
                className="info-icon"
                name="info_circle_outlined"
              />]}
            </Tooltip>
          </span>
        </div>
        <div className="tax-block-value">
          <Typography
            variant="subhead-regular"
          >
            {`${getPrettierFormatNumber(taxValueAmount)} ${currency}`}
          </Typography>
        </div>
      </div>
    </div>
  );
};
