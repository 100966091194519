/* eslint-disable camelcase */
import { Dispatch } from '@reduxjs/toolkit';
import {
  PayHubCashierContractsEnumsSubmitPaymentStatus as PaymentStatus,
  PayHubCashierContractsPaymentSessionStateResponse,
  PayHubDomainSessionsEnumsPlatformType,
} from 'store/models';
import { setPaySessionState } from 'store/slices/paySessionState';
import { Timeout } from 'react-number-format/types/types';
import { PAGE_STATUS } from 'utils';
import { statusRedirectOnProcessing } from 'Modulor/components/SubmitForm/utils';
import { returnUrlFormer } from 'Modulor/Status/utils';
import { FlowTypes } from 'store/constants';

interface CreatorProps {
  dispatch: Dispatch;
  timer: string | number | Timeout | undefined;
  platform: PayHubDomainSessionsEnumsPlatformType;
  flowType: FlowTypes;
}

export const paySessionStateHandlerCreator = (
  creatorProps: CreatorProps,
) => async (
  json: string,
  stopConnection: () => Promise<void>,
) => {
  const {
    dispatch,
    timer,
    platform,
    flowType,
  } = creatorProps;
  const data = JSON.parse(json) as PayHubCashierContractsPaymentSessionStateResponse;

  if (data.error_fatal) {
    const {
      success_url = '',
      processing_url = '',
      fail_url = '',
      merchant_url = '',
    } = data;
    const urlToRedirect = returnUrlFormer({
      success_url,
      processing_url,
      fail_url,
      decodedUrl: '',
      merchant_url,
      flowType,
    });
    statusRedirectOnProcessing({
      platform,
      urlToRedirect: urlToRedirect(PAGE_STATUS.error),
    });
    clearTimeout(timer);
    await stopConnection();
    return;
  }

  const isResultStatus = data.action === 'result' && [
    PaymentStatus.Success,
    PaymentStatus.Fail,
  ].includes(data.result_status as PaymentStatus);

  if (data.session_id) {
    const {
      session_id,
      success_url,
      fail_url,
      processing_url,
      merchant_url,
    } = data;
    const returnUrls = {
      session_id,
      success_url,
      fail_url,
      processing_url,
      merchant_url,
    };
    dispatch(setPaySessionState(returnUrls));
  }

  if (data.session_id && isResultStatus) {
    dispatch(setPaySessionState(data));
    await stopConnection();
  }
};
