import { isMobile } from 'react-device-detect';
import { FlowTypes } from 'store/constants';
import { Parameter, PlatformsViewport } from 'store/models';
import { softLocalStorageGet, softSessionStorageGet, softSessionStorageRemove, softSessionStorageSet } from './storage';
import {
  CLOSE_ICON,
  LOCAL_THEME,
  LayoutType,
  POP_UP,
  DEFAULT_THEME_NAME,
  DEFAULT_WIDGET_APP_HEIGHT,
  WIDGET_HEIGHT_GAP,
} from './constants';
import { getWidgetLocation, parseQuery } from './locactionAndUrls';
import { THEME_TYPE, THEME_TYPE_LIST } from '../services/theme';
import { DynamicTypeObject, FlatStringObject } from '../commonTypes';
import { stringToInt } from './dataTransform';

export function inIframe():boolean {
  try {
    if (window?.top?.location.href === window.location.href) {
      return false;
    }

    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const getCurrentQueryParams = (): string => window.self.location.search || '';

export const resetFocus = (): void => {
  if (document.activeElement) {
    (document.activeElement as HTMLElement).blur();
  }

  setTimeout(() => {
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
  }, 500);
};

export const getSubmitCheckoutUrl = (flowType: FlowTypes, paygroupId: string): string => {
  const type = flowType === FlowTypes.deposit ? 'payments' : 'payouts';

  return `${type}/groups/${paygroupId}/submit`;
};

export const getIsABCasinoButton = () => softLocalStorageGet('cashier_ab_casino_button') || false;

export const setDeviceType = (): void => {
  if (isMobile) {
    try {
      document.documentElement.dataset.device = 'mobile';
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Unable to set data-device attribute to HTML tag.', error);
    }
  }
};

export const getLayoutType = ():LayoutType => (isMobile ? LayoutType.mobile : LayoutType.desktop);

export const setLayoutType = (): void => {
  document.documentElement.dataset.layoutType = getLayoutType();
};

export const setCloseIconMode = (): void => {
  const params = parseQuery(window.location.search) as FlatStringObject;
  const isAlwaysClose = Boolean(params?.close_icon);
  if (!isAlwaysClose) {
    const { isStatusPage } = getWidgetLocation(window.location.pathname);

    if (!isStatusPage) {
      softSessionStorageRemove(CLOSE_ICON);
      return;
    }
  }

  try {
    document.documentElement.dataset.alwaysCloseIcon = CLOSE_ICON;
    softSessionStorageSet(CLOSE_ICON, 'true');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Unable to set data attribute to documentElement.', e);
  }
};

export const setOpenMode = (): void => {
  const params = parseQuery(window.location.search) as FlatStringObject;
  const isPopUp = Boolean(params?.in_pop_up);

  if (isPopUp) {
    try {
      document.documentElement.dataset.openMode = POP_UP;
      softSessionStorageSet(POP_UP, POP_UP);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Unable to set data attribute to documentElement.', e);
    }
  } else {
    const { isStatusPage } = getWidgetLocation(window.location.pathname);

    if (!isStatusPage) {
      softSessionStorageRemove(POP_UP);
    }
  }
};

export const getIsCloseIcon = (): boolean => {
  try {
    const dataAttribute = document.documentElement.dataset.alwaysCloseIcon;
    const params = parseQuery(window.location.search) as FlatStringObject;
    const storedAlwaysCloseIcon = softSessionStorageGet(CLOSE_ICON);
    return Boolean(params.close_icon || dataAttribute === CLOSE_ICON || storedAlwaysCloseIcon);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Unable to get open mode.', e);
    return false;
  }
};

export const getIsOpenedInPopUp = (): boolean => {
  try {
    const dataAttribute = document.documentElement.dataset.openMode;
    const params = parseQuery(window.location.search) as FlatStringObject;
    const storedPopUp = softSessionStorageGet(POP_UP);

    return Boolean(params.in_pop_up || dataAttribute === POP_UP || storedPopUp);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Unable to get open mode.', e);
    return false;
  }
};

export const getFormattedThemeName = (theme?: string): THEME_TYPE => {
  if (!theme) {
    return DEFAULT_THEME_NAME;
  }

  const formattedThemeNameAsArray = theme.match(/brand\d{1,2}/gm);

  return (formattedThemeNameAsArray?.length ? formattedThemeNameAsArray[0] : theme) as THEME_TYPE;
};

export const getTheme = (): THEME_TYPE => {
  const theme = document.body.dataset.definedTheme as THEME_TYPE;

  return getFormattedThemeName(theme);
};

export const getInitialTheme = () => {
  const params = parseQuery(getCurrentQueryParams());
  const { theme = '' } = params as {theme?: THEME_TYPE};

  return theme;
};

export const getThemeByBrand = (brand: string): THEME_TYPE => {
  // NOTE: for more info about brands mapping,
  // please go to https://confluence.pm.tech/pages/viewpage.action?spaceKey=PD&title=Design+Tokens
  const THEME_BRAND_MAP: FlatStringObject = {
    NOMAD: 'brand6',
    GLS: 'brand1',
    FLEX: 'brand8',
    SCCOM: 'brand8',
  };
  const mappedBrandTheme = THEME_BRAND_MAP[brand];
  const params = parseQuery(getCurrentQueryParams());
  const { theme = DEFAULT_THEME_NAME } = params as {theme?: THEME_TYPE};
  const formattedThemeName = getFormattedThemeName(theme);
  const themeFormQuery = THEME_TYPE_LIST.includes(formattedThemeName) ? formattedThemeName : '';

  const storageTheme = softLocalStorageGet(LOCAL_THEME);

  return (mappedBrandTheme || themeFormQuery || storageTheme || DEFAULT_THEME_NAME) as THEME_TYPE;
};

export const getSubmitButtonVariant = (isValid: boolean) => (
  isValid ? 'deposit' : 'primary');

export const displayTooltip = (show: boolean) => {
  const modulorTooltip = document.querySelector('[data-component-name="modulor-tooltip"]');
  if (modulorTooltip) {
    modulorTooltip.setAttribute('style', `visibility: ${show ? 'visible' : 'hidden'};`);
  }
};

export const setRenderedAttributes = () => {
  if (inIframe()) {
    try {
      document.documentElement.dataset.renderedAttribute = 'iframe';
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
};

export const setReqHeaders = (headers: Headers, sessionId: string) => {
  if (sessionId) {
    headers.set('x-session-id', sessionId);
  } else {
    throw new Error('no sessionId');
  }

  return headers;
};

export const getRequisiteFieldNameList = (parameters: Parameter[]): string[] => {
  if (!parameters.length) {
    return [];
  }

  return parameters.reduce((acc, current) => {
    if (current.userRequisite) {
      acc.push(current.name);
    }

    return acc;
  }, [] as string[]);
};

export const getTranslationMessage = (
  t: (key?: string, options?: DynamicTypeObject) => (string),
  value: string | undefined,
  params: DynamicTypeObject | undefined = {},
) => {
  const convertedValue = value?.replaceAll(':', '&#xa789;') || '';
  return t(convertedValue, {
    ...params,
  });
};

export const getGroupAttributes = () => ({
  currencyAttr: document.body.dataset.currency,
  payGroupIdAttr: document.body.dataset.payGroupId ? stringToInt(document.body.dataset.payGroupId) : 0,
});

export const getAppHeight = ({
  navBarHeight,
  headerHeight,
  modalHeight,
  height,
}: PlatformsViewport) => {
  const contentHeight = (
    window.document.getElementById('root')?.getBoundingClientRect().height
  ) || DEFAULT_WIDGET_APP_HEIGHT;
  const openedInIframe = inIframe();
  const platformHeaders = getIsOpenedInPopUp() ? navBarHeight : headerHeight + navBarHeight;
  const platfromsModalHeight = modalHeight - platformHeaders;
  const contentHeightWithGap = contentHeight + WIDGET_HEIGHT_GAP;

  const platformsViewportHeight = height - platformHeaders;
  const requiredDesktopHeight = contentHeight > platformsViewportHeight ? (
    contentHeightWithGap) : platformsViewportHeight;
  const requiredModalHeightInIFrame = (
    platfromsModalHeight < contentHeightWithGap ? contentHeightWithGap : platfromsModalHeight);
  const requiredHeightInIFrame = getIsOpenedInPopUp() ? requiredModalHeightInIFrame : requiredDesktopHeight;
  return openedInIframe ? requiredHeightInIFrame : contentHeightWithGap;
};
