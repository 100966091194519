import { Base64 } from 'js-base64';
import { FieldValues } from 'react-hook-form';
import { getExpirationObject } from 'store/formatters';
import {
  PayHubAdminPanelDomainEnumsParameterDataType,
  Parameter,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ExtendedTypes, Option,
} from 'store/models';
import { getPattern } from 'utils';
import { CardState } from 'store/slices/userCard';
import { DynamicTypeObject } from '../commonTypes';

export function encodeToBase64(data: Record<string, unknown> | string): string {
  try {
    const dataString = typeof data === 'string' ? data : JSON.stringify(data);

    return Base64.encode(dataString);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return `${data}`;
  }
}

export const convertValueToFormat = (value: string | number, type: string | undefined): string | number => {
  if (type === 'number' && typeof value === 'string') {
    return parseFloat(value);
  }
  if (type === 'string' && typeof value === 'number') {
    return value.toString();
  }
  return value;
};

export const setNullToNotRequiredFields = (
  requiredFields: Parameter[],
  fields: DynamicTypeObject,
  dirtyFields: Partial<Record<keyof FieldValues, boolean>> = {},
): DynamicTypeObject => {
  const preparedFields: DynamicTypeObject = {};
  const requiredFieldList = requiredFields.reduce((acc: DynamicTypeObject, {
    name,
    isRequired,
  }) => {
    acc[name] = isRequired;

    return acc;
  }, {});

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < Object.entries(fields).length; i++) {
    const [key, value] = Object.entries(fields)[i];
    if (key === 'amount') {
      preparedFields.amount = value;
      // eslint-disable-next-line no-continue
      continue;
    }

    if (!requiredFieldList[key] && ![
      'number',
      'boolean',
    ].includes(typeof value) && !value
    ) {
      if (dirtyFields[key]) {
        preparedFields[key] = null;
      } else {
        delete preparedFields[key];
      }
    } else {
      preparedFields[key] = value;
    }
  }

  return preparedFields;
};

interface AdditionalParams {
  isCvvPresent?: boolean;
  isCardTokenActive?: boolean;
  isCheckout?: boolean;
}
export const prepareParametersForSubmit = (
  fields: FieldValues,
  additionalParams: AdditionalParams,
  isNew?: boolean,
) => {
  const data = {
    ...fields,
  };
  const {
    isCardTokenActive = false,
    isCheckout = false,
  } = additionalParams;

  if (fields.expirationDate) {
    const expirationDataObj = getExpirationObject(fields.expirationDate, isCheckout);
    delete data.expirationDate;
    Object.assign(data, expirationDataObj);
  }

  if (data.card_id && !isNew) {
    delete data.card_pan;
  }

  if (isCheckout && !isNew) {
    data.is_card_token_active = isCardTokenActive;
  }

  if (fields.card_cardholder_name) {
    data.card_cardholder_name = fields.card_cardholder_name.trim();

    if (!data.card_cardholder_name) {
      delete data.card_cardholder_name;
    }
  }

  const result: Record<string, string | number | unknown> = {};

  Object.keys(data).forEach((item) => {
    const fieldValue: string | number | unknown | Option = fields[item];
    if (fieldValue && typeof fieldValue === 'object' && 'value' in fieldValue) {
      result[item] = fieldValue.value;
    } else {
      result[item] = fieldValue;
    }
  });

  return data;
};

const sortFieldsByDefinedOrder = (arr: Parameter[], orederArr: ExtendedTypes[]) => arr.sort((a, b) => {
  const aIndex = orederArr.indexOf(a.extendedType as ExtendedTypes);
  const bIndex = orederArr.indexOf(b.extendedType as ExtendedTypes);
  if (aIndex === -1) {
    return 1;
  }
  if (bIndex === -1) {
    return -1;
  }
  return aIndex - bIndex;
});

export const prepareFieldsWithCustomParameters = (
  fields: Parameter[],
  isCVVPresent: boolean,
  cardInfo?: CardState,
): Parameter[] => {
  const SKIPPED_FIELDS = [
    ExtendedTypes.CardExpYear,
    ExtendedTypes.CardExpMonth,
    ExtendedTypes.CardCvv,
  ];

  const orderedTypes = [
    ExtendedTypes.CardPan,
    ExtendedTypes.Custom,
    ExtendedTypes.CardCardholderName,
  ];

  const pairParameter: Parameter = {
    name: 'pair',
    key: 'pair',
    type: 'pair',
    extendedType: ExtendedTypes.Custom,
    dataType: PayHubAdminPanelDomainEnumsParameterDataType.String,
    items: [],
    customRegExp: {},
  };

  const fieldMonth = fields.find(({ extendedType }) => extendedType === ExtendedTypes.CardExpMonth);
  const fieldYear = fields.find(({ extendedType }) => extendedType === ExtendedTypes.CardExpYear);

  if (fieldMonth && fieldYear) {
    const customParameter: Parameter = {
      extendedType: ExtendedTypes.Custom,
      name: 'expirationDate',
      key: 'expirationDate',
      type: fieldMonth.type,
      dataType: fieldMonth.dataType,
      isRequired: fieldMonth.isRequired || fieldYear.isRequired,
      validationKeys: {
        month: fieldMonth.validationMsgKey,
        year: fieldYear.validationMsgKey,
      },
    };

    const patternMonth = getPattern(fieldMonth.regexp);
    if (patternMonth) {
      customParameter.customRegExp = {
        month: patternMonth,
      };
    }

    const patternYear = getPattern(fieldYear.regexp);
    if (patternYear) {
      customParameter.customRegExp = {
        ...customParameter.customRegExp,
        year: patternYear,
      };
    }
    pairParameter.items?.push(customParameter);
  }

  const shouldHasCVV = isCVVPresent || cardInfo?.selectedCard?.isNew || !cardInfo?.selectedCard?.is_card_token_active;

  const PAIR_PARAMETER_TYPES = shouldHasCVV ? [
    ExtendedTypes.Custom,
    ExtendedTypes.CardCvv,
  ] : [
    ExtendedTypes.Custom,
  ];
  fields.forEach((field: Parameter) => {
    if (field.extendedType && PAIR_PARAMETER_TYPES.includes(field.extendedType)) {
      pairParameter.items?.push(field);
    }
  });
  if (pairParameter.items?.length) {
    fields.push(pairParameter);
  }

  const filtered = fields.filter(({ extendedType }) => !SKIPPED_FIELDS.includes(extendedType as ExtendedTypes));
  return sortFieldsByDefinedOrder(filtered, orderedTypes);
};

export const formatMethodName = (methodName?: string) => {
  if (!methodName) {
    return '';
  }

  return methodName
    .trim()
    .toLowerCase()
    .replace(/ /gi, '_');
};

export const stringToInt = (value: string, radix = 10) => parseInt(value, radix);

export const getFormDefaultValues = (fields: Parameter[]): FieldValues => {
  const defaultValues : { [key: string]: string | number | boolean } = {};
  const typeDefaultValues: { [key: string]: string | number | boolean } = {
    string: '',
    number: 0,
    boolean: false,
  };

  fields.forEach((field) => {
    const dataType = field.dataType.toLowerCase();
    defaultValues[field.name] = field.userRequisite || typeDefaultValues[dataType] || '';
  });

  return defaultValues;
};

export const getSymbolFromCurrency = (value: string) => value.toUpperCase();
// BILL-14731
// currencySymbolMapping[value] || value

export const getPrettierFormatNumber = (value?: number | string) => (
  value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') || ''
);

export const getStringWithoutSpaces = (value: string) => value.replace(/\s/g, '');

export const formatAmount = (value: string | number): string => {
  try {
    const numberValue = Number(value);
    const locale = 'ru-RU';
    const options = {
      style: 'currency',
      currency: 'USD',
    };

    return new Intl.NumberFormat(locale, options).format(numberValue).replace('$', '');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return `${value}`;
  }
};
