import { FC, useEffect, useMemo } from 'react';
import { Button } from '@modulor/react';
import { FieldValues, UseFormSetError, useForm } from 'react-hook-form';
import {
  Limits,
  MerchantUserFeeResponce,
  Parameter,
  PayHubCashierContractsViewDataResponse,
  PayHubWidgetContractsSubmitPaymentResponse,
  PayHubWidgetContractsSubmitPayoutResponse, ProcessingCurrency,
} from 'store/models';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { FlowTypes } from 'store/constants';
import { bonusDataSelector } from 'store/slices/bonus';
import { TaxContainer } from 'Modulor/components/Tax/TaxContainer';
import {
  useCustomTranslation,
  useDetectNewErrors,
  useFrameChangeHeight,
  useGetTaxInfo,
  usePrefillAutosubmit,
} from 'Modulor/hooks';
import {
  isSelfTargetSelector,
  methodNameSelector,
  setIsAutoSubmitted,
} from 'store/slices/deposit';
import { platformSelector, setAmount } from 'store/slices/global';
import { getFormDefaultValues, getRequisiteFieldNameList, getSubmitButtonVariant, getSymbolFromCurrency } from 'utils';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query';
import {
  Clickstream,
  getClickStreamCashierContext,
  getClickStreamPayGroupCashierContext,
  getClickStreamEvent,
} from 'services/clickstream';
import { DepositSubmitRequestProps } from 'store/services/depositAPI';
import { isOTPEnabledSelector } from 'store/slices/otp';
import { fieldGetter, getAmountField } from 'Modulor/fieldGetter';
import { getPrettierFormatNumber } from 'utils/dataTransform';
import { getSubmitSubText, getValidPresettedAmount } from './utils';
import { AmountBlocks } from '../components/AmountBlocks';
import { BonusInfo } from '../components/BonusInfo';
import { Fee } from '../components/Fee';
import { InfoLink } from '../components/InfoLink';
import { getAmountBlocksByExperiment } from '../components/utils';

import './DepositForm.scss';
import AdditionalInfo from '../components/AdditionalInfo';
import { ConversionBlock } from '../components/ConversionBlock';
import { getGBFeatures } from '../../services/growthbook';
import { ProcessingTime } from '../components/ProcessingTime';

interface DepositFormProps {
  amount: number;
  formConfig: Parameter[];
  amountBlocks?: number[];
  amountRegExpFe?: string;
  limits: Limits | null;
  submitCallback: (
    fields: FieldValues,
    setError: UseFormSetError<FieldValues>,
    dirtyFields: Partial<Record<keyof FieldValues, boolean>>,
  ) => void;
  merchantUserFee?: MerchantUserFeeResponce;
  currency: string;
  isFetchingSubmitRequest: boolean;
  presetCurrency?: string | null;
  isAutoSubmit?: boolean;
  isMethodLoaded?: boolean;
  submitDepositTrigger: LazyQueryTrigger<QueryDefinition<DepositSubmitRequestProps,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
    never, PayHubWidgetContractsSubmitPayoutResponse | PayHubWidgetContractsSubmitPaymentResponse, 'depositAPI'>>;
  payGroupId: number;
  additionalViewData?: PayHubCashierContractsViewDataResponse[] | null;
  fee?: string | null;
  presetAmount?: number | null;
  processingCurrency?: ProcessingCurrency;
  processingCurrencyRate?: number;
  descriptionUrl?: string;
  processingTimeTranslationKey?: string;
}

const gbFeatures = getGBFeatures();

export const DepositForm: FC<DepositFormProps> = (props) => {
  const {
    amount,
    submitCallback,
    formConfig,
    amountBlocks,
    amountRegExpFe,
    limits,
    currency,
    isFetchingSubmitRequest,
    presetCurrency,
    isAutoSubmit,
    isMethodLoaded,
    submitDepositTrigger,
    payGroupId,
    additionalViewData,
    merchantUserFee,
    fee,
    presetAmount,
    processingCurrency,
    processingCurrencyRate,
    descriptionUrl,
    processingTimeTranslationKey,
  } = props;
  const dispatch = useAppDispatch();
  const clickstream = Clickstream.use();
  const gbAmountBlocksResult = gbFeatures.growthBook.evalFeature('billing.recommended.amount');
  const amountBlocksData = getAmountBlocksByExperiment({
    amountBlocks, limits, gbResult: gbAmountBlocksResult?.value,
  });
  const cashierContext = getClickStreamCashierContext(FlowTypes.deposit);
  const payGroupContext = getClickStreamPayGroupCashierContext();
  const bonusData = useAppSelector(bonusDataSelector);
  const isOTPEnabled = useAppSelector(isOTPEnabledSelector);
  const methodName = useAppSelector(methodNameSelector);
  const platform = useAppSelector(platformSelector);
  const isSelfTarget = useAppSelector(isSelfTargetSelector);
  const { t } = useCustomTranslation();
  const currencySymbol = getSymbolFromCurrency(presetCurrency || currency);
  const currencyValue = presetCurrency || currency;
  const { bonusInfo, bonusByPayGroup, isGeneralBonus } = bonusData || {};
  const filteredFormConfig = isOTPEnabled ? formConfig.slice(0, -1) : formConfig;
  const requisiteFieldNameList = getRequisiteFieldNameList(formConfig);
  const defaultValues = getFormDefaultValues(formConfig);
  const isBonusIncluded = isGeneralBonus || Boolean(bonusByPayGroup?.includes(payGroupId));

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    trigger,
    getValues,
    formState: {
      isValid,
      errors,
      dirtyFields,
    },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { hasNewErrors } = useDetectNewErrors(errors);

  useFrameChangeHeight(hasNewErrors);

  useEffect(() => {
    if (amount) {
      setValue('amount', amount, {
        shouldValidate: true,
      });
    }
  }, [amount]);

  useEffect(() => {
    if (isAutoSubmit && hasNewErrors) {
      dispatch(setIsAutoSubmitted(true));
    }
  }, [hasNewErrors]);

  useEffect(() => {
    const eventOpen = getClickStreamEvent.cashier_method_open;
    clickstream.push(eventOpen, [cashierContext,
      payGroupContext]);

    if (requisiteFieldNameList.length) {
      requisiteFieldNameList.forEach((name) => trigger(name));
    }
  }, []);

  const { tax, calculateNonTaxableAmount, amountWithTaxes } = useGetTaxInfo(
    amount,
    FlowTypes.deposit,
  );

  const hasAmountBlocks = !!amountBlocksData?.length;
  const setAmountFromBlock = (amountFieldValue: number) => {
    dispatch(setAmount(`${amountFieldValue}`));
    setValue('amount', amountFieldValue, {
      shouldValidate: true,
    });
  };

  const amountField = getAmountField({
    amountRegExpFe,
    limits,
    currency: currencyValue,
    flowType: FlowTypes.deposit,
    isDisabled: isAutoSubmit,
  });

  usePrefillAutosubmit({
    autoSubmit: isAutoSubmit,
    isMethodLoaded,
    amountValue: getValidPresettedAmount({
      presetAmount: presetAmount || 0,
      amountBlocks,
      min: limits?.min,
      max: limits?.max,
    }),
    currency: currencyValue,
    payGroupId,
    isValid,
    submitDepositTrigger,
    validate: trigger,
    values: getValues(),
  });

  const newLocal = fieldGetter({
    setError,
    setValue,
    clearErrors,
    formField: {
      control,
    },
  })(amountField);

  const submitSubText = useMemo(() => getSubmitSubText({
    t,
    currency: currencyValue,
    amount,
    bonusInfo,
    processingCurrency,
    processingCurrencyRate,
    merchantUserFee,
  }), [
    currency,
    amount,
    bonusInfo,
    processingCurrency,
    processingCurrencyRate,
    merchantUserFee,
  ]);

  const shouldAutoSubmit = amount && isAutoSubmit && !hasNewErrors && !formConfig.some((param) => param.isRequired);

  if (shouldAutoSubmit) {
    return null;
  }

  return (
    <form
      className="deposit-form"
      onSubmit={handleSubmit((fields) => submitCallback(fields, setError, dirtyFields))}
    >
      <div className="deposit-form__content">
        { bonusInfo && isBonusIncluded && (
        <BonusInfo {...bonusInfo} />
        )}
        {newLocal}
        {hasAmountBlocks && (
          <AmountBlocks
            currentAmount={amount}
            amountBlocks={amountBlocksData}
            onClick={setAmountFromBlock}
            currency={currencyValue}
            isAutoSubmit={isAutoSubmit}
          />
        )}
        <ProcessingTime message={processingTimeTranslationKey} />
        <Fee
          fee={fee}
          merchantUserFee={merchantUserFee}
        />
        <TaxContainer
          tax={tax}
          isTaxHintShown
          calculateNonTaxableAmount={calculateNonTaxableAmount}
          taxAmount={amountWithTaxes.taxAmount}
          amount={amount}
        />
        <ConversionBlock
          amount={amount}
          methodName={methodName}
          processingCurrency={processingCurrency}
          processingCurrencyRate={processingCurrencyRate}
        />
        {filteredFormConfig.map(
          fieldGetter({
            setError,
            setValue,
            clearErrors,
            formField: {
              control,
            },
          }),
        )}
      </div>
      <div className="deposit-form__footer">
        <InfoLink
          methodName={methodName}
          platform={platform}
          descriptionUrl={descriptionUrl}
          isSelfTarget={isSelfTarget}
          flowType={FlowTypes.deposit}
        />
        <AdditionalInfo additionalData={additionalViewData} />
        <Button
          type="submit"
          disabled={!isValid}
          loading={isFetchingSubmitRequest}
          variant={getSubmitButtonVariant(isValid)}
          doubleText={submitSubText}
        >
          {t('PH.BUTTON.DEPOSIT_AMOUNT', {
            amount: getPrettierFormatNumber(amount),
            currency_icon: currencySymbol || currency,
          })}
        </Button>
      </div>
    </form>
  );
};
