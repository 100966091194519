/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ListView, ListCell, Radio, Button, Icon, RadioGroup } from '@modulor/react';
import { setSelectedCard, selectedCardSelector, showDeleteCardMode, SelectedCardType } from 'store/slices/userCard';
import { PayHubWidgetContractsUserCardResponse } from 'store/models';
import { useCustomTranslation, useFrameChangeHeight, useNavigateWithSearch } from 'Modulor/hooks';
import { getCardsPlaceholder, getCardsIcon, getCardsTypeName } from './utils';
import { DeleteModal } from './DeleteModal';

import './Cards.scss';

export const SavedCards = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigateWithSearch();
  const { isDeleteCardMode, selectedCard, cardsList } = useAppSelector(selectedCardSelector);
  const { t } = useCustomTranslation();
  const [isOpened, toggleModal] = useState<boolean>(false);
  const [selectedCardId, setSelectedCardId] = useState<number | null | undefined>(null);
  useFrameChangeHeight(!!cardsList?.length);

  useEffect(() => () => {
    dispatch(showDeleteCardMode(false));
  }, []);

  const onSelectCard = (card: SelectedCardType) => {
    dispatch(setSelectedCard(card));
    navigate(-1);
  };

  const onClickDeleteBtn = (card: PayHubWidgetContractsUserCardResponse) => {
    if (isDeleteCardMode) {
      toggleModal(true);
      return setSelectedCardId(card.id);
    }
    return onSelectCard(card);
  };

  return (
    <>
      <DeleteModal
        isOpened={isOpened}
        toggleModal={toggleModal}
        selectedCardId={selectedCardId}
      />
      <ListView className="cards-container-wrapper">
        {cardsList?.map((card: PayHubWidgetContractsUserCardResponse) => (
          <ListCell
            key={card.id}
            className="card-box"
            text={getCardsTypeName(card.bin)}
            subtitle={getCardsPlaceholder(card.last4, card.bin)}
            image={getCardsIcon(card.bin)}
            onClick={() => onClickDeleteBtn(card)}
            content={isDeleteCardMode ? (
              <Button
                variant="text_button"
                className="action__in-list-cell remove-card-btn"
              >
                <Icon
                  name="delete_outlined"
                  fill="text-error"
                />
              </Button>
            ) : (
              <RadioGroup onChange={() => {}}>
                <Radio
                  className="action__in-list-cell radio-btn"
                  value={card.id?.toString() || ''}
                  checked={card.id === selectedCard?.id}
                />
              </RadioGroup>
            )}
          />
        ))}
        {!isDeleteCardMode && (
          <ListCell
            className="card-box new-card"
            text={t('PH.DEPOSIT.PAY_NEW_CARD')}
            rightContentAlignment="start"
            image={<Icon name="credit_card_outlined" />}
            onClick={() => onSelectCard({
              isNew: true,
            })}
            content={(
              <RadioGroup onChange={() => {}}>
                <Radio
                  className="action__in-list-cell radio-btn"
                  value=""
                  checked={selectedCard.isNew}
                />
              </RadioGroup>
            )}
          />
        )}
      </ListView>
    </>
  );
};
